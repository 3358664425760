import React, { useState } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components";
import Swal from "sweetalert2";
import TextField from "@material-ui/core/TextField";
import withReactContent from "sweetalert2-react-content";
import { Helmet } from "react-helmet";
import {
  getUA,
  isMobile,
  isBrowser,
  isTablet,
  isDesktop,
  isConsole,
  isAndroid,
  isWinPhone,
  isIOS,
  isChromium,
  isMobileSafari,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isEdge,
} from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";

import banner from "../images/banner.png";
import kakao from "../images/kakao.png";
import mobileTop from "../images/mobile-top.png";
import mobileBottom from "../images/mobile-bottom.png";
import naver from "../images/naver.png";
import youtube from "../images/youtube.png";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;
`;

const Header = styled.header`
  background: linear-gradient(
    118.85deg,
    rgba(181, 184, 194, 0.7) 5.85%,
    #e7e9f0 67.76%
  );
  margin-bottom: 40px;
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  width: 100vw;
  background: #4e4f55;
`;

const FooterText = styled.p`
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #8c8f98;
`;

const Top = styled.header`
  background: #376dcc;
  padding: 23px 0;
`;

const Bottom = styled.header`
  background: #e7e9f0;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 40px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 920px;
`;

const VerticalView = styled.div`
  display: flex;
  flex-direction: column;
`;

const HorizontalView = styled.div`
  display: flex;
  flex-direction: row;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 414px) {
    flex-direction: column;
    align-items: start;
  }
`;

const Kakao = styled.div`
  background-image: url(${kakao});
  width: 38px;
  height: 38px;
  background-size: contain;
  background-position: center;
`;

const BigKakao = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 30px;
  background-image: url(${kakao});
  width: 62px;
  height: 62px;
  background-size: contain;
  background-position: center;
`;

const Naver = styled.div`
  background-image: url(${naver});
  width: 38px;
  height: 38px;
`;

const Youtube = styled.div`
  background-image: url(${youtube});
  width: 38px;
  height: 38px;
  margin-left: 8px;
  margin-right: 8px;
`;

const Title = styled.p`
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #4e4f55;
  align-items: center;
  margin-bottom: 20px;
`;

const TitleText = styled.p`
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #4e4f55;
  margin-bottom: 40px;
`;

const Text = styled.p`
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #4e4f55;
  word-wrap: break-word;
  word-break: keep-all;
`;

const TextBlue = styled.span`
  color: #376dcc;
  font-style: normal;
  font-weight: 500;
`;

const TextRed = styled.span`
  color: #d63031;
  font-style: normal;
  font-weight: 500;
`;

const Line = styled.hr`
  width: 80vw;
  max-width: 920px;
  border: 0.5px solid #e7e9f0;
  margin: 30px 0;
  align-self: center;
`;

const Lint = styled.p`
  font-family: Apple SD Gothic Neo;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #8c8f98;
  word-wrap: break-word;
  word-break: keep-all;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  background: #f5f8ff;
  border-radius: 5px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 15px 0px 0px;

  @media (max-width: 414px) {
    margin: 0px 0px 15px 0px;
  }
`;

const HeaderImage = styled.div`
  background-image: url(${banner});
  height: 60vh;
  width: 100vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const MobileTop = styled.div`
  background-image: url(${mobileTop});
  height: 100px;
  width: 100vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const MobileBottom = styled.div`
  background-image: url(${mobileBottom});
  height: 400px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const JumpButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    borderRadius: 5,
    width: 312,
    alignSelf: "center",
    paddingTop: 15,
    paddingBottom: 15,
    marginTop: 40,
    marginBottom: 40,
    backgroundColor: "#376DCC",
    "&:hover": {
      backgroundColor: "#6098f3",
    },
  },
}))(Button);

const JumpCheckbox = withStyles({
  root: {
    color: "#376DCC",
    "&$checked": {
      color: "#376DCC",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const MySwal = withReactContent(Swal);

const IndexPage = () => {
  let token = "";
  let type = "";
  let campaign = "";
  let eventStart = false;
  if (typeof document !== "undefined") {
    let params = new URLSearchParams(document.location.search.substring(1));
    token = params.get("token");
    if (token)
      typeof window !== "undefined" && window.gtag("set", { user_id: token }); // 로그인한 User-ID를 사용하여 User-ID를 설정합니다.
    type = params.get("t");
    campaign = params.get("c");
  }
  let first = true;
  React.useEffect(() => {
    if (first) handleVisit();
  }, []);

  const [inputs, setInputs] = useState({
    company: "",
    name: "",
    phone: "",
    email: "",
    etc: "",
  });

  const [state, setState] = React.useState({
    own: false,
    rent: false,
    invest: false,
    other: false,
  });

  const { company, name, phone, email, etc } = inputs;
  const { own, rent, invest, other } = state;

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheck = (event) => {
    if (!eventStart) {
      eventStart = true;
      typeof window !== "undefined" && window.gtag("event", "event start");
    }
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleClick = () => {
    first = false;
    if (
      company !== "" &&
      name !== "" &&
      phone !== "" &&
      email !== "" &&
      (own || rent || invest || other)
    ) {
      // sendApply();
      handleLambda();
      sendSheet();
      typeof window !== "undefined" && window.gtag("event", "complete");
    } else {
      MySwal.fire({
        title: "모든 칸을 입력하여 주세요.",
        icon: "error",
        confirmButtonText: "확인",
      });
    }
  };

  const handleLambda = () => {
    let interest = "";
    if (own) interest += " 사옥 마련(실입주) ";
    if (rent) interest += " 사무실 구하기(임대) ";
    if (invest) interest += " 부동산 투자 ";
    if (other) interest += " 기타 ";

    try {
      MySwal.fire({
        title: "상담신청 완료!",
        text: "담당자가 확인 후 연락드리겠습니다.",
        icon: "success",
        confirmButtonText: "확인",
      }).then((result) => {
        if (result.value) {
          window.location.reload();
        }
      });
      axios
        .post(
          "https://9h17rh3rcc.execute-api.ap-northeast-2.amazonaws.com/prod/consulting-requests",
          {
            company,
            content: `${interest} ${etc}`,
            email,
            name,
            phone,
            source: `[이메일v${campaign}](https://landing.jumpprop.com/)`,
          }
        )
        .then((resp) => console.log(resp))
        .catch((err) => sendApply());
    } catch (e) {
      MySwal.fire({
        title: "오류가 생겼습니다.",
        text: "다시 시도하여 주세요.",
        icon: "error",
        confirmButtonText: "확인",
      });
    }
  };

  const handleVisit = () => {
    try {
      axios
        .get(
          "https://script.google.com/macros/s/AKfycbwJSdf4jR8Cr-otf0KhxsRD6jIpLh9i_fdHxclQR4hjpTLbEhJZov_exWX08zt-huY7qg/exec",
          {
            params: {
              token,
              type,
              campaign,
              UA: getUA,
              isMobile,
              isBrowser,
              isTablet,
              isDesktop,
              isConsole,
              isAndroid,
              isWinPhone,
              isIOS,
              isChromium,
              isChrome,
              isFirefox,
              isSafari,
              isOpera,
              isIE,
              isEdge,
              isMobileSafari,
            },
          }
        )
        .then((resp) => console.log(resp))
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };

  const sendSheet = () => {
    let interest = "";
    if (own) interest += " 사옥 마련(실입주) ";
    if (rent) interest += " 사무실 구하기(임대) ";
    if (invest) interest += " 부동산 투자 ";
    if (other) interest += " 기타 ";

    try {
      axios
        .get(
          "https://script.google.com/macros/s/AKfycbyprvpF-IjgB70JA2P2hq434qM4W-1TQJAC5mqfNZTIsgOY0z6TcBgFvMoYjedHQ6K5/exec",
          {
            params: {
              회사이름: company,
              이름: name,
              전화번호: phone,
              이메일: email,
              기타문의: etc,
              관심분야: interest,
              source: "상담신청페이지",
              token,
              type,
              campaign,
              UA: getUA,
              isMobile,
              isBrowser,
              isTablet,
              isDesktop,
              isConsole,
              isAndroid,
              isWinPhone,
              isIOS,
              isChromium,
              isChrome,
              isFirefox,
              isSafari,
              isOpera,
              isIE,
              isEdge,
              isMobileSafari,
            },
          }
        )
        .then((resp) => console.log(resp))
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  };

  const sendApply = async () => {
    let interest = "";
    if (own) interest += " 사옥 마련(실입주) ";
    if (rent) interest += " 사무실 구하기(임대) ";
    if (invest) interest += " 부동산 투자 ";
    if (other) interest += " 기타 ";

    try {
      let payload = {
        connectColor: "#376DCC",
      };

      let data = [];

      const text =
        "회사이름 : " +
        company +
        "\n이름 : " +
        name +
        "\n전화번호 : " +
        phone +
        "\n이메일 : " +
        email +
        "\n기타문의 : " +
        etc +
        "\n관심분야 : " +
        interest;

      payload["body"] = `잠재고객 상담문의 version${campaign}`;

      data = [{ title: "고객정보", description: text }];

      payload["connectInfo"] = data;

      const config = {
        method: "post",
        url:
          "https://wh.jandi.com/connect-api/webhook/22375411/04cd8356b29f07eebf68d2e5d3684cc1",
        headers: {
          Accept: "application/vnd.tosslab.jandi-v2+json",
          "Content-Type": "application/json",
        },
        data: payload,
      };

      axios(config)
        .then(function (response) {
          MySwal.fire({
            title: "상담신청 완료!",
            text: "담당자가 확인 후 연락드리겠습니다.",
            icon: "success",
            confirmButtonText: "확인",
          }).then((result) => {
            if (result.value) {
              window.location.reload();
            }
          });
        })
        .catch(function (error) {
          MySwal.fire({
            title: "오류가 생겼습니다.",
            text: "다시 시도하여 주세요.",
            icon: "error",
            confirmButtonText: "확인",
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Container>
      <Helmet>
        <html lang="ko" />
        <meta charSet="utf-8" />
        <title>점프컴퍼니 상담 신청</title>
        <meta name="description" content="점프컴퍼니 상담 신청 페이지" />
        <meta property="og:title" content="점프컴퍼니 상담 신청" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://landing.jumpprop.com/" />
        <meta
          property="og:image"
          content="https://user-images.githubusercontent.com/4162725/111097335-aa0ba800-8584-11eb-8940-e85c605e72fb.png"
        />
        <meta property="og:image:type" content="image/png" />
      </Helmet>
      {!isMobile ? (
        <Header>
          <HeaderImage />
        </Header>
      ) : (
        <VerticalView>
          <Top>
            <MobileTop />
          </Top>
          <Bottom>
            <MobileBottom />
          </Bottom>
        </VerticalView>
      )}

      <Section>
        <TitleText>상담 신청하기</TitleText>
        {!isMobile ? (
          <Text>
            오랜 경험과 정확한 DB를 바탕으로, 지식산업센터{" "}
            <TextBlue>투자 및 운용</TextBlue>에 대한 설계,
            <br />
            <TextBlue>사무실 비용절감</TextBlue> 솔루션을 전문 매니저가 직접
            상담 해 드립니다.
          </Text>
        ) : (
          <Text>
            오랜 경험과 정확한 DB를 바탕으로, 지식산업센터{" "}
            <TextBlue>투자 및 운용</TextBlue>에 대한 설계,
            <TextBlue>사무실 비용절감</TextBlue> 솔루션을 전문 매니저가 직접
            상담 해 드립니다.
          </Text>
        )}

        <Line />

        <Title>
          1. 관심분야를 선택해 주세요.<TextRed>＊</TextRed>{" "}
          <TextBlue>중복 선택이 가능합니다</TextBlue>
        </Title>
        <Horizontal>
          <Box>
            <FormControlLabel
              control={
                <JumpCheckbox
                  checked={state.own}
                  onChange={handleCheck}
                  name="own"
                />
              }
              label="사옥 마련 (실입주)"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <JumpCheckbox
                  checked={state.rent}
                  onChange={handleCheck}
                  name="rent"
                />
              }
              label="사무실 구하기 (임대)"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <JumpCheckbox
                  checked={state.invest}
                  onChange={handleCheck}
                  name="invest"
                />
              }
              label="부동산 투자"
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <JumpCheckbox
                  checked={state.other}
                  onChange={handleCheck}
                  name="other"
                />
              }
              label="기타"
            />
          </Box>
        </Horizontal>

        <Line />
        <VerticalView>
          <Title>
            2. 회사 이름을 입력해주세요.<TextRed>＊</TextRed>
          </Title>
          <TextField
            variant="outlined"
            name="company"
            placeholder="회사이름을 입력해 주세요."
            onChange={handleChange}
            type="text"
            required
          />
          <Line />
        </VerticalView>

        <Title>
          3. 담당자님 성함을 입력해주세요.<TextRed>＊</TextRed>
        </Title>
        <TextField
          variant="outlined"
          name="name"
          placeholder="담당자 성함"
          onChange={handleChange}
          type="text"
          required
        />
        <Line />

        <Title>
          4. 연락 가능한 번호를 입력해주세요.<TextRed>＊</TextRed>
        </Title>
        <TextField
          variant="outlined"
          name="phone"
          placeholder="01012345678"
          onChange={handleChange}
          type="tel"
          required
        />
        <Line />

        <Title>
          5. 연락 가능한 이메일 주소를 입력해주세요.<TextRed>＊</TextRed>
        </Title>
        <TextField
          variant="outlined"
          name="email"
          placeholder="somebody@example.com"
          onChange={handleChange}
          type="email"
        />
        <Line />

        <Title>6. 궁금한 내용 혹은 관심 지역이 있는 경우 남겨주세요.</Title>
        <TextField
          variant="outlined"
          name="etc"
          placeholder="추가 질문을 적어주세요."
          multiline
          onChange={handleChange}
          type="text"
        />
        <Line />

        <Lint>
          * 투자자에게는 안정적인 임대 수익과 매도차익이 기대되는 물건을
          소개하고, 사업하고 계시는 대표분들께는 사무실 비용 절감 솔루션을 전문
          매니저가 상담 후 제공하는 서비스입니다.
          <br /> * 신청을 클릭하면 회원님의 정보를 JUMP에 전송하며, 해당
          페이지에서 개인정보처리방침에 따라 이 정보를 사용하는것에 동의하게
          됩니다.
          <br />
          <TextBlue>
            <a href="https://jumpapp.co.kr/Policy/PrivacyPolicy">
              [JUMP 페이지의 개인정보처리방침 보기]
            </a>
          </TextBlue>
        </Lint>

        <JumpButton variant="contained" onClick={handleClick}>
          신청하기
        </JumpButton>
      </Section>

      <Footer>
        <HorizontalView style={{ marginBottom: 14 }}>
          <a
            href="https://cafe.naver.com/jumpapp"
            onClick={() => {
              typeof window !== "undefined" &&
                window.gtag("event", "visit cafe");
            }}
          >
            <Naver />
          </a>
          <a
            href="https://www.youtube.com/channel/UCdECXJBS2WIBA_0jiBMNDKw"
            onClick={() => {
              typeof window !== "undefined" &&
                window.gtag("event", "visit youtube");
            }}
          >
            <Youtube />
          </a>
          <a
            href="http://pf.kakao.com/_edZCK/chat"
            onClick={() => {
              typeof window !== "undefined" &&
                window.gtag("event", "visit kakao");
            }}
          >
            <Kakao />
          </a>
        </HorizontalView>
        <FooterText>JUMP COMPANY</FooterText>
        <FooterText>
          HQ: 서울 서초구 강남대로 53길 8, 11층 1103호
          <br />
          R&D: 경기 수원시 영통구 대학4로 17, 에이스광교타워1차 711호
          <br />
          분양현장 등록 / 분양대행 / 솔루션 문의 :{" "}
          <a href="tel:1670-1207">1670-1207</a>
        </FooterText>
      </Footer>
      <a
        href="http://pf.kakao.com/_edZCK/chat"
        onClick={() => {
          typeof window !== "undefined" &&
            window.gtag("event", "visit big kakao");
        }}
      >
        <BigKakao />
      </a>
    </Container>
  );
};

export default IndexPage;
